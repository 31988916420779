<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2022-02-15 11:13:02
 * @LastEditors: ------
 * @LastEditTime: 2022-02-17 10:37:16
-->
<template>
  <div>
    <van-nav-bar
      title="服务合同管理"
      left-text="返回"
      fixed
      left-arrow
      placeholder
      @click-left="$router.go(-1)"
    />
    <van-form @submit="onSubmit">
      <van-field
        label="服务类型"
        name=""
        input-align="right"
        :value="
          obj.type == 0
            ? '钟点服务'
            : obj.type == 1
            ? '短期应急（医院陪护）'
            : obj.type == 2
            ? '24小时生活照护'
            : '适老无障碍设施改造'
        "
        disabled
      />

      <van-field
        readonly
        clickable
        :value="value"
        label="护理标准"
        placeholder="选择护理标准"
        input-align="right"
        @click="showPicker = true"
        :rules="[{ required: true }]"
      />

      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-field
        v-model="Info.num"
        name="num"
        label="合同时长"
        input-align="right"
        :rules="[{ required: true }]"
      >
        <template #button>
          <van-button size="small" style="border: none" disabled
            >个月</van-button
          >
        </template>
      </van-field>

      <van-field
        name="servicePeriod"
        label="服务周期"
        :rules="[{ required: true }]"
        input-align="right"
        v-if="obj.type == 0"
      >
        <template #input>
          <van-checkbox-group v-model="checkboxGroup" direction="horizontal">
            <van-checkbox name="1" checked-color="#FF9A31">周一</van-checkbox>
            <van-checkbox name="2" checked-color="#FF9A31">周二</van-checkbox
            ><van-checkbox name="3" checked-color=" #FF9A31">周三</van-checkbox
            ><van-checkbox name="4" checked-color=" #FF9A31">周四</van-checkbox
            ><van-checkbox name="5" checked-color=" #FF9A31">周五</van-checkbox>
            <van-checkbox name="6" checked-color=" #FF9A31">周六</van-checkbox>
            <van-checkbox name="7" checked-color="  #FF9A31">周日</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <van-field
        readonly
        clickable
        name="startTime"
        :value="value1"
        label="服务时间（上午）"
        placeholder="点击选择时间"
        input-align="right"
        label-width="9em"
        @click="showPicker1 = true"
        :rules="[{ required: true }]"
        v-if="obj.type == 0"
      />
      <van-popup v-model="showPicker1" position="bottom">
        <van-datetime-picker
          type="time"
          @confirm="onConfirm1"
          @cancel="showPicker1 = false"
        />
      </van-popup>
      <van-field
        readonly
        clickable
        name="endTime"
        :value="value2"
        label="服务时间（下午）"
        placeholder="点击选择时间"
        input-align="right"
        label-width="9em"
        @click="showPicker2 = true"
        v-if="obj.type == 0"
        :rules="[{ required: true }]"
      />
      <van-popup v-model="showPicker2" position="bottom">
        <van-datetime-picker
          type="time"
          @confirm="onConfirm2"
          @cancel="showPicker2 = false"
        />
      </van-popup>
      <van-field
        readonly
        clickable
        name="calendar"
        :value="value3"
        label="服务日期"
        placeholder="点击选择日期"
        @click="showCalendar3 = true"
        input-align="right"
        :rules="[{ required: true }]"
      />
      <van-calendar
        v-model="showCalendar3"
        type="range"
        color="#FFD385"
        @confirm="onConfirm3"
      />
      <div style="margin: 16px" class="btn">
        <van-button
          round
          block
          color="linear-gradient(-90deg, #FF9A31, #FFD385)"
          native-type="submit"
          >下一步，选择服务类型</van-button
        >
      </div>
    </van-form>
  </div>
</template>

<script>
import { getServeTypeCon } from "@/api/index.js";
import moment from "moment";
export default {
  name: "",
  data() {
    return {
      name: "",
      obj: null,
      results: [],
      value: "",
      value1: "",
      value2: "",
      value3: "",
      showPicker: false,
      showPicker1: false,
      showPicker2: false,
      showCalendar3: false,
      columns: [],
      checkbox: false,
      checkboxGroup: [],
      Info: {
        serviceId: "",
        serviceSpecsId: "",
        // servicePeriod: "",
        // startTime: "",
        // endTime: "",
        appointmentStartTime: "",
        appointmentEndTime: "",
        num: "1",
      },
    };
  },
  created() {
    this.obj = JSON.parse(this.$route.query.obj);
    console.log(this.obj);
    this.init();
  },
  methods: {
    onSubmit(values) {
      if (this.obj.type == 0) {
        values.servicePeriod = values.servicePeriod.toString();
        values.startTime = "0000-00-00 " + values.startTime + ":00";
        values.endTime = "0000-00-00 " + values.endTime + ":00";
      }

      values.appointmentStartTime = this.start1;
      values.appointmentEndTime = this.end1;
      values.serviceSpecsId = this.Info.serviceSpecsId;
      values.serviceId = this.Info.serviceId;
      values = Object.assign(this.obj, values);
      this.$router.push({
        path: "/payment",
        query: {
          obj: JSON.stringify(values),
        },
      });
    },
    onConfirm(value) {
      this.value = value.text;
      this.Info.serviceSpecsId = value.serviceSpecsId;

      this.showPicker = false;
    },
    onConfirm1(time) {
      this.value1 = time;
      this.showPicker1 = false;
    },
    onConfirm2(time) {
      this.value2 = time;
      this.showPicker2 = false;
    },
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm3(date) {
      this.showCalendar3 = false;
      const [start, end] = date;
      this.start1 =
        moment(start).format("YYYY-MM-DD") +
        "T" +
        moment(start).format("HH:mm:ss") +
        ".000Z";
      this.end1 =
        moment(end).format("YYYY-MM-DD") +
        "T" +
        moment(end).format("HH:mm:ss") +
        ".000Z";
      this.value3 = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    },
    async init() {
      //请求服务类型
      let res = await getServeTypeCon({
        _t: 1625732025,
        cityId: this.obj.serviceCity,
      });
      this.results = res.data.result[this.obj.type];
      // this.columns = this.results.serviceSpecs;
      this.results.serviceSpecs.map((item) => {
        console.log(item);
        this.columns.push({ text: item.name, serviceSpecsId: item.id });
      });
      console.log(this.columns);
      this.Info.serviceId = this.results.id;
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .van-nav-bar {
  background: #ff9a31;

  .van-nav-bar__title {
    color: #fff;
  }
}

/deep/ .van-nav-bar__left {
  .van-icon,
  .van-nav-bar__text {
    color: #fff;
  }
}
/deep/ .van-checkbox-group {
  div {
    margin: 3px 4px;
  }
}
.btn {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
